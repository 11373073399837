export const state = () => ({
    loading: false,
    languageStrings: {},
    errorStrings: {},
})

export const actions = {
    async fetchLanguageStrings({ commit }, localeLang) {
        commit('setLoading', true)
        try {
            const response = await this.$axios.get(
                '/language-strings/language/' + localeLang
            )
            const convertArrayToObject = Object.fromEntries(
                response.data.map((item) => [item.code, item.string])
            )
            commit('setLanguageString', convertArrayToObject)
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    async getErrorStrings({ commit }, langCode = 'en-US') {
        await this.$axios
            .get('/error-codes', {
                params: {
                    lang_code: langCode,
                },
            })
            .then((data) => {
                commit('setErrorStrings', { data: data.data, lang: langCode })
            })
            .catch(() => {})
    },
}

export const mutations = {
    setLoading: (state, v) => {
        state.loading = v
    },
    setLanguageString: (state, v) => {
        state.languageStrings = v
    },
    setErrorStrings: (state, data) => {
        const { data: errorStrings, lang } = data

        // Change array of objects to object for easier access,
        // Flatten the text object to make it easier to access
        const flattenedObject = errorStrings.reduce((acc, item) => {
            acc[item.code] = item.text[lang]
            return acc
        }, {})

        state.errorStrings = flattenedObject
    },
}

export const getters = {
    localeLanguage: (state) => state.languageStrings,
    tl: (state) => (code) => {
        return state.languageStrings[code] || code
    },
}
