// This regex is given by Backend Team
export const PLAYER_REGEX = /^[a-z][a-z0-9]{3,10}[a-z0-9]$/

export const FRONTEND_ERROR_CODES = {
    pleaseTryAgain: 110003,
    tryAgainAfterSomeTime: 110003,
    isRequired: 110055,
    currencyIsRequired: 110013,
    playerIdIsRequired: 110017,
    amountIsRequired: 110037,
    decimalIsTwoDigit: 110042,
    accountHolderNameIsRequired: 110018,
    accountHolderNameShouldBeAlphabetsAndDotsOnly: 110019,
    accountNumberIsRequired: 110015,
    bankNameIsRequired: 110021,
    phoneNumberIsRequired: 110014,
    required: 110004,
    walletAddressIsRequired: 110028,
    networkIsRequired: 110056,
    cryptoWalletAddressIsRequired: 110043,
    inValidWalletAddress: 110029,
    passwordIsRequired: 110012,
    passwordGreaterThan6: 110034,
    passwordNotMatch: 110008,
    bankAccountNumberIsRequired: 110052,
    phoneIsRequired: 110051,
    bankAccountLimitExceeded: 110000,
    pleaseCheckCondition: 110001,
    registrationIsNotAvailable: 110002,
    pleaseTryAgainAfterSometime: 110003,
    bankIsRequired: 110005,
    bankNumberIsRequired: 110006,
    newPasswordRequired: 110007,
    accountNumberShouldContainOnlyNumber: 110016,
    bankNameShouldBeValid: 110058,
    enterYourPreferredPlayerId: 110022,
    min5Length: 110023,
    max12Length: 110024,
    invalid: 110025,
    bankAccountHolderNameIsRequired: 110026,
    cryptoNetworkIsRequired: 110027,
    emailFormatInvalid: 110030,
    birthDateIsRequired: 110031,
    socialMediaIdIsRequired: 110032,
    platformIsRequired: 110033,
    oldPasswordIsRequired: 110035,
    confirmPasswordIsRequired: 110036,
    amountGreaterThanZero: 110038,
    balanceInsufficient: 110039,
    minimumWithdrawIs: 110040,
    maximumWithdrawIs: 110041,
    minDepositIs: 110044,
    maxDepositIs: 110045,
    amountMustBeGreatThan: 110048,
    amountMustBeLessThan: 110049,
    countryIsRequired: 110053,
    phoneNumberFormatInvalid: 110054,
}
