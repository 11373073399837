const pendingRequests = new Map()
const getRequestKey = (config) => `${config.method}:${config.url}`
export default function (context) {
    let isLoginRoute = false

    // This hideSuccessToast, is used to hide the success message from API response manually
    // To use this, add hideToast: true in the params of the API request
    let hideSuccessToast = false
    const utcTime = (date) => {
        return context
            .$dayjs(date || new Date())
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
    }

    const setAgentDomainHeader = (config) => {
        if (process.client) {
            // production
            config.headers.common['agent-domain'] = window?.location?.host
            // testing purposes
            // config.headers.common['agent-domain'] = 'wing90.com'
        }
    }

    const setDateParamsToUTC = (config) => {
        const params = { ...config.params } || {}
        if (params.start_date) params.start_date = utcTime(params.start_date)
        if (params.end_date) params.end_date = utcTime(params.end_date)
        config.params = params
    }

    context.$axios.onRequest((config) => {
        const requestKey = getRequestKey(config)

        // Create temporary cancel token if not already exists
        if (!config.cancelToken) {
            // Create a new cancel token
            const source = context.$axios.CancelToken.source()
            config.cancelToken = source.token
            pendingRequests.set(requestKey, source)
        }
        if (config.url === '/players/login') isLoginRoute = true
        if (context.store.state.auth.rejectAllApiRequests) {
            return Promise.reject(new Error('All requests are blocked'))
        }
        setAgentDomainHeader(config)
        setDateParamsToUTC(config)
        if (config.params.hideToast) {
            hideSuccessToast = true
        }
        delete config.params.hideToast
        if (process.server) {
            config.headers.origin = `https://${context.app.$config.DOMAIN}`
        }
        config.baseURL = context.app.$config.API_DOMAIN
        if (context.$cookie.get('token')) {
            if (config.headers.common) {
                config.headers.common.Authorization = `${context.$cookie.get(
                    'token'
                )}`
            }
        }
    })

    context.$axios.interceptors.response.use(
        (response) => {
            // Delete the request from the request's token
            const requestKey = getRequestKey(response.config)
            pendingRequests.delete(requestKey)

            // The success toast will work only when the response is in error message format
            if (
                typeof response?.data?.code === 'number' &&
                response?.data?.error === false &&
                hideSuccessToast !== true
            ) {
                const {
                    message: responseMessage,
                    code: responseMessageStatusCode,
                } = response.data

                const errorStrings =
                    context.store.state.themes['language-string'].errorStrings
                const displayMessageText =
                    errorStrings[responseMessageStatusCode] || responseMessage
                context.$toast.success(`${displayMessageText}`)
            }
            const isJSON =
                response.headers['content-type'].includes('application/json')
            return isJSON ? response : Promise.reject(response)
        },
        (error) => {
            return retryRequest(error)
        }
    )

    const retryRequest = async (error) => {
        const { config, message } = error
        if (!config || !config.retry) return Promise.reject(error)

        if (message.includes('timeout') || message.includes('Network Error')) {
            config.retry -= 1
            await new Promise((resolve) =>
                setTimeout(resolve, config.retryDelay || 1000)
            )
            return context.$axios(config)
        }
        return Promise.reject(error)
    }

    context.$axios.onError((error) => {
        handleError(error)
    })

    const handleError = (error) => {
        if (process.client) {
            if (error?.response?.status === 302) {
                const redirectUrl = error.response?.data?.redirect || null
                if (redirectUrl) {
                    window.location.href = redirectUrl
                    return
                }
            }
            try {
                // Since Wrong username and password are also response http = 401
                if (error?.response?.status === 401 && isLoginRoute !== true) {
                    try {
                        cancelAllRequests()
                        showToastMessage(
                            context.store.state.themes['language-string']
                                .errorStrings[error?.response?.data?.code] ||
                                error?.response?.data?.message ||
                                'Unauthorized'
                        )
                        context.store.commit(
                            'auth/setRejectAllApiRequests',
                            true
                        )
                        context.$cookie.remove('token')
                        context.$cookie.remove('player')
                        context.store.dispatch('settings/toggleIsLogin', false)
                        window.location.href = '/'
                    } catch (error) {}
                } else {
                    const errorMessagesFromStore =
                        context.store.state.themes['language-string']
                            .errorStrings
                    // Ignore clear toast if this error occurred because of API is block
                    if (error?.message !== 'All requests are blocked') {
                        context.$toast?.clear()
                    }
                    if (error?.response && error?.response?.data?.message) {
                        const {
                            code: responseErrorCode,
                            error: responseErrorStatus,
                            message: responseErrorMessage,
                        } = error?.response?.data

                        if (responseErrorStatus) {
                            const displayErrorMessage =
                                errorMessagesFromStore[responseErrorCode] ||
                                responseErrorMessage
                            showToastMessage(displayErrorMessage)
                        }
                    } else if (error.code === 'ECONNABORTED') {
                        // Timeout error case, error-code: 95014
                        context.$toast.error(
                            errorMessagesFromStore[95014] ||
                                'API response timeouts'
                        )
                    } else if (!navigator.onLine) {
                        // No Internet Connection, error-code: 95012
                        context.$toast.error(
                            errorMessagesFromStore[95012] || 'Network Errors'
                        )
                    } else if (error.response?.status === 429) {
                        // Too many API requests, error-code: 95013
                        context.$toast.error(
                            errorMessagesFromStore[95013] || 'API Rate Limiting'
                        )
                    } else {
                        showToastMessage(
                            context?.tl('pleaseTryAgainAfterSometime')
                        )
                    }
                }
            } catch (error) {}
        }
    }

    const showToastMessage = (message) => {
        context.$toast?.error(message)
    }
}

const cancelAllRequests = () => {
    pendingRequests.forEach((source) => source.cancel(''))
    pendingRequests.clear()
}
