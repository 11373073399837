// State
export const state = () => ({
	promotions: [],
	promotionId: [],
	loading: false,
})

// Actions
export const actions = {
	// get all promotions
	async promotions({ commit }) {
		commit('loading', true)
		try {
			const response = await this.$axios
				.get('/promotions')
			commit('setPromotions', response.data)
		} catch (error) {

		} finally {
			commit('loading', false)
		}
	},
	async promotionCode({ commit }, form) {
		commit('loading', true)
		try {
			await this.$axios
				.get(`/promotion-codes/claim/${form}`)
		} catch (error) {

		} finally {
			commit('loading', false)
		}

	},
	async claimNowRolling({ commit }, form) {
		try {
			await this.$axios
				.put(`/rolling-reward/rolling/claim`, {})
			return true;
		} catch (error) {
			return false;
		}
	}
}

// Mutations
export const mutations = {
	setPromotion: (state, v) => {
		state.promotionId = v
	},
	setPromotions: (state, data) => {
		state.promotions = data
	},
	loading: (state, type) => {
		state.loading = type
	},
}

// Getters
export const getters = {
	promotions: (state) => {
		return state.promotions
	},
}
