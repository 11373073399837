/**
 * Ticket: IW-3600
 */
export default async ({ $vuetify, app }, inject) => {
    if (!app.$config.THEME) return

    // Define available themes
    const themes = () => import(`~/assets/themes/${app.$config.THEME}`)

    // Load and apply a theme dynamically
    const applyTheme = async () => {
        const themeConfig = (await themes()).default

        // Extend the default theme instead of replacing it
        $vuetify.theme.themes.light = {
            ...$vuetify.theme.themes.light, // Retain existing colors
            ...themeConfig.light, // Merge new light theme colors
        }

        $vuetify.theme.themes.dark = {
            ...$vuetify.theme.themes.dark, // Retain existing colors
            ...themeConfig.dark, // Merge new dark theme colors
        }
    }

    // Apply default theme on app load
    await applyTheme()
}
